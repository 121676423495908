<template>
  <div>
    <!-- BREADCRUMBS -->
    <custom-breadcrumb
      :pageTitle="$t('Groups')"
      :breadcrumb="breadcrumb"
    ></custom-breadcrumb>
    
    <!-- ADD GROUP -->
    <add-new
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :school_id="school_id"
      @refetch-data="getData"
    />

    <!-- IMPORT WIZARD  -->
    <import-wizard
      v-if="showImportModal"
      @close="showImportModal = false"
      :school_id="$route.params.id"
    ></import-wizard>
    
    <b-card no-body v-else>
      <!-- FILTROS -->
      <filters
        ref="filter"
        context="groups"
        :school_id="school_id"
        @set-data="setData"
        @set-loading-data="setloadingData"
      /> 

      <!-- TABLA -->
      <paginated-table
        :data="groups"
        ability="SchoolGroups"
        link='school-groups'
        @manage-selected-rows="manageSelectedRows"
        @delete="deleteModel"
        :canRecover="false"
        :canDownload="true"
        :loading_data="loading_data"     
        :school_id="school_id"


        :buttonsOptions="!showImportModal ? buttonsOptions: importOptions"
        @close="showImportModal = false"
        @create="isAddNewSidebarActive = true"
        @import="showImportModal = true"
        @deleteSelection="deleteSelection"
      ></paginated-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol
} from 'bootstrap-vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import PaginatedTable from '@/views/sl-components/PaginatedTable.vue'
import Filters from '@/views/sl-components/Filters.vue'
import AddNew from './AddNew.vue'
import ImportWizard from '@/views/sl-components/ImportExcelWizard.vue'

export default {
  components:{
    BCard, BRow, BCol,

    CustomBreadcrumb,
    PaginatedTable,
    ImportWizard,
    Filters,
    AddNew
  },
  data(){
    return {
      showImportModal: false,
      importOptions: [
        {
          action: "close",
          icon: "XIcon",
          name: this.$t('import_wizard.go_back_to_list')
        },
      ],
      isAddNewSidebarActive: false,
      selected: [],
      groups: [],
      loading_data: false,

      school_id: '',
      school:{}
    }
  },
  computed: {
    breadcrumb(){

          if(this.$store.getters['user/isSchool']){
            return [
              {
                text: this.$t('Schools'),
              },
              {
                text: this.school.name
              },
              {
                text: this.$t('Groups'),
                active: true,
              },
            ]
          } else {
            return [
              {
                text: this.$t('Schools'),
                to: 'schools'
              },
              {
                to: 'school-view',
                params: {
                  id: this.$route.params.id,
                },
                text: this.school.name
              },
              {
                text: this.$t('Groups'),
                active: true,
              },
            ]
          }
    },
    buttonsOptions(){
        let opt = [
          {
            action: "create",
            icon: "PlusIcon",
            variant: "success",
            name: this.$t('forms.add')
          },
          /* {
            action: "import",
            icon: "UploadIcon",
            name: "Import Group"
          }, */
        ]
        if(this.selected.length > 0){
          opt.push({
                action: "deleteSelection",
                icon: "Trash2Icon",
                name: this.$t('table.delete_selection'),
                variant: "danger",
              })
        }
        return opt
      },
  },
  methods: {
    manageSelectedRows(selected_rows){
      console.log(selected_rows, "selected_rows")
      this.selected = selected_rows
    },
    async getData(){
        console.log("loading")
        let data = {
          school_id : this.$route.params.id ?? null
        }
        await this.$http.post('school/groups',data).then( response =>{
          console.log(response)
          this.groups = response.data.groups;
          this.school = response.data.school
          //this.setData(response);
          if(this.$store.getters['user/isSchool']){
            this.school_id = this.$store.getters['user/isSchool'].id
          } else {
            this.school_id = this.$route.params.id;
          }

        }).catch(error => {
          console.log("Err -> ", error)
      })
    },
    deleteSelection(){
        this.$bvModal
          .msgBoxConfirm(this.$t('table.delete_selection_confirmation', {num:this.selected.length}), {
            title: this.$t('table.delete_selection'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value){
              let sel = this.selected.map(item => item.id)
              let data = {
                'groups':   sel
              }
              console.log(data)
              this.$http.post('school/group/deleteSelectedGroups', data).then( response =>{
                console.log(response)
                if(response.data.status == 200){
                  this.$refs.filter.search();
                  this.getData()
                  this.makeToast('success', this.$t('Success'), this.$t('forms.delete_all.success'));

                } else {
                  this.makeToast('danger', this.$t('Error'), this.$t('forms.delete_all.error'));
                }

              }).catch(error => {
                console.log("Err -> ", error)
              })
            }
          })
      
    },
    deleteModel(id){
      this.$bvModal
        .msgBoxConfirm(this.$t('forms.delete_group_confirmation'), {
          title: this.$t('forms.delete'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value){
              let data = {
                'id': id
              }
              this.$http.post('school/group/delete', data).then( response =>{
                console.log(response)
                if(response.data.status == 200){
                  this.$refs.filter.search();
                  this.getData()
                  this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));

                } else {
                  this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
                }

              }).catch(error => {
                console.log("Err -> ", error)
              })
            }
          })
    },
    recoverModel(){
      //
    },
    setloadingData(state){
        this.loading_data = state
    },
    setData(data){
      console.log("set data", data)
        if(data.groups.length > 40000){
            this.groups = []
            this.fetch_message = $t('table.too_many_records')
            this.loading_data = false
            //this.groups.length = 0
        } else {
            this.fetch_message = ''
            this.groups = data.groups;
            this.loading_data = false
            //this.groups.length = data.groups_length
        }
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  },
  mounted(){
        this.getData()
  }
}
</script>

<style>

</style>